import React from "react";
import { academy, client_domain } from "../assets/js/utils/constants";

class Top_header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div class="container" style={{ zIndex: 9999 }}>
        <div class="header_section_top">
          <div class="row">
            <div class="col-sm-12">
              <div class="custom_menu">
                <ul>
                  <li>
                    <a href={academy}>Acedemy</a>
                  </li>
                  <li>
                    <a href={client_domain}>Shop</a>
                  </li>
                  <li>
                    <a href={`${academy}/contact`}>Customer Service</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Top_header;
